export const keyFeaturesSegment = {
    minHeight: "125px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 0
};

export const keyFeaturesHeader = {
    marginBottom: "14px",
    textTransform: "uppercase"
};

export const howToUseHeader = {
    marginBottom: "14px",
    marginTop: "5px",
    textTransform: "uppercase",
    textAlign: "center"
};

export const useAEurButton = {
    backgroundColor: "white",
    borderTopRightRadius: "14px",
    borderBottomRightRadius: "14px",
    color: "#01385a",
    display: "flex",
    flexDirection: "column",
    padding: "25px 20px",
    textAlign: "center"
};

export const howItWorksRail = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center"
};

export const howItWorksRailBox = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#01385a",
    fontSize: "35px",
    lineHeight: "35px"
};

export const howItWorksRailBoxSpan = {
    fontSize: "14px",
    lineHeight: "14px",
    fontWeight: "300"
};

export const howItWorksImage = {
    minHeight: "79px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
};
